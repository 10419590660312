import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/layout";
import img_service_1 from "../images/service/service_1.png";
import img_service_2 from "../images/service/service_2.png";
import img_service_3 from "../images/service/service_3.png";

const LongStatement = (props) => {
  return (
    <div className="text-xs md:text-base leading-5 md:leading-10">
      <p>{props.children}</p>
    </div>
  );
};

const ServicePage = () => {
  return (
    <Layout>
      <div class="container mx-auto">
        <div class="mx-auto text-center">
          <h1>
            <div class="relative md:block text-white">
              <StaticImage src="../images/service/service_top.png" />

              <div
                class="absolute top-0 text-left"
                style={{ marginTop: "8.5%", marginLeft: "10%" }}
              >
                <div class="text-sm md:text-2xl lg:text-3xl font-bold">
                  サービス案内
                </div>
                <div class="text-xs md:text-base lg:text-lg font-semibold">
                  SERVICE
                </div>
              </div>
            </div>
          </h1>

          <h2 class="py-8">
            <div class="text-darkblue text-2xl font-semibold">サービス案内</div>
            <div class="text-secondary text-lg">SERVICE</div>
          </h2>
        </div>

        {/* SECTION 1  */}
        <div
          class="w-full bg-right bg-auto"
          style={{
            backgroundImage: `url(${img_service_1})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "right bottom",
          }}
        >
          <div class="px-6 py-4 md:px-16 md:pt-12 md:pb-20">
            <div class="flex flex-row justify-between items-start">
              <h3 class="text-sm md:text-3xl font-medium mb-4 md:mb-8 w-7/12 border-l-4 border-darkblue pl-2">
                <span class="leading-tight">
                  自動化システム装置の設計・製作
                </span>
              </h3>
              <div class="text-huge text-white font-black md:transform md:scale-150">
                1
              </div>
            </div>
            <LongStatement>
              自動化システム装置の設計・製作
              <br />
              これまで培ってきた技術をベースに「お困りごと」を解決する自動化装置を提案します。
              <br />
              「構想設計」「機械設計」「電機設計」「装置組立」「制御盤製作」「機械配線」「調整」「デバック」「据付」まで一貫して対応します。
              <br />
              これまで自動車部品製造工程の自動化装置、半導体部品製造工程の自動化装置の導入実績。
              <br />
              これからの環境問題に対応するため「エコ関連」の自動装置など販売していきます。
              <br />
            </LongStatement>
          </div>
        </div>

        {/* AFTER SECTION 1  */}
        <div class="w-full md:w-10/12 mx-auto mt-8 md:-mt-8 mb-16 md:mb-32">
          <div class="flex flex-col md:flex-row w-full mb-12 md:mb-0 md:items-center">
            <div class="w-full text-center md:w-1/2 md:text-left">
              <StaticImage
                src="../images/service/assembly_1.jpg"
                className="w-4/6 md:w-11/12"
              />
            </div>

            <div class="text-right w-5/6 md:w-1/2 ml-0 mr-auto">
              <div
                class="w-full border-b border-gray-500"
              >
                工場での組立作業
              </div>
            </div>
          </div>

          <div class="flex flex-col-reverse md:flex-row w-full md:items-center">
            <div class="text-left w-5/6 md:w-1/2 ml-auto mr-0">
              <div class="w-full border-b border-gray-500">
                工場での組立作業
              </div>
            </div>

            <div class="w-full text-center md:w-1/2 md:text-right">
              <StaticImage
                src="../images/service/assembly_2.jpg"
                className="w-4/6 md:w-11/12"
              />
            </div>
          </div>
        </div>

        {/* SECTION 2  */}
        <div
          class="w-full bg-right bg-auto"
          style={{ backgroundImage: `url(${img_service_2})` }}
        >
          <div class="px-6 py-4 md:px-16 md:pt-12 md:pb-20">
            <div class="flex flex-row justify-between items-start">
              <h3 class="text-sm md:text-3xl font-medium mb-4 md:mb-8 w-7/12 md:w-11/12 border-l-4 border-darkblue pl-2">
                <span class="leading-tight">
                  お客様のご要望に合わせた対応が可能です
                </span>
              </h3>
              <div class="text-huge text-white font-black md:transform md:scale-150">
                2
              </div>
            </div>
            <LongStatement>
              お客様のご要望に合わせた対応が可能です
              <br />
              装置の設計・組立だけの対応も可能。お客様のご要望に合わせて対応していきます。
              <br />
              機械加工品も一品からでも対応可能です。
              <br />
              旋盤加工・フライス・板金・製缶品など自動機部品全般で対応可能。
              <br />
              材質も炭素鋼・ステンレス・アルミ・樹脂など多数の実績があります。
              <br />
              「短納期対応」を実現しています。
              <br />
              装置開発向けの部品など超短納期品にも対応します。
            </LongStatement>
          </div>
        </div>

        {/* AFTER SECTION 2  */}
        <div class="w-full md:w-10/12 mx-auto mt-8 md:-mt-8 mb-16 md:mb-32">
          <div class="flex flex-col-reverse md:flex-row w-full md:items-center">
            <div class="text-left w-5/6 md:w-1/2 ml-auto mr-0">
              <div class="w-full border-b border-gray-500">部品加工実績</div>
            </div>

            <div class="w-full text-center md:w-1/2 md:text-right">
              <StaticImage
                src="../images/service/manufacturing.jpg"
                className="w-4/6 md:w-11/12 "
              />
            </div>
          </div>
        </div>

        {/* SECTION 3  */}
        <div
          class="w-full bg-right bg-auto"
          style={{
            backgroundImage: `url(${img_service_3})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "left top",
            backgroundSize: "cover",
          }}
        >
          <div class="px-6 py-4 md:px-16 md:pt-12 md:pb-20">
            <div class="flex flex-row justify-between items-start">
              <h3 class="text-sm md:text-3xl font-medium mb-4 md:mb-8 w-7/12 md:w-10/12 border-l-4 border-darkblue pl-2">
                <span class="leading-tight">
                  「アフターサービス」と「品質保証」
                </span>
              </h3>
              <div class="text-huge text-white font-black md:transform md:scale-150">
                3
              </div>
            </div>
            <LongStatement>
              「品質」「価格」「納期」すべてが揃うことが顧客満足である。それが私たちTACの合言葉です。
              <br />
              装置のデバック・試運転、現地据付工事から流動確認、および生産立会まで一貫して対応します。
              <br />
              また部品の交換、万が一のトラブルにも即対応します。
              <br />
              部品加工品は1点ずつ検査を実施、品質を保証します。
            </LongStatement>
          </div>
        </div>

        {/* AFTER SECTION 3  */}
        <div class="w-full md:w-10/12 mx-auto mt-8 md:-mt-8 mb-16 md:mb-32">
          <div class="flex flex-col md:flex-row w-full mb-12 md:mb-0 md:items-center">
            <div class="w-full text-center md:w-1/2 md:text-left">
              <StaticImage
                src="../images/service/qc.jpg"
                className="w-4/6 md:w-11/12"
              />
            </div>

            <div class="text-right w-5/6 md:w-1/2 ml-0 mr-auto">
              <div class="w-full border-b border-gray-500">
                組立前の部品検査
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ServicePage;
